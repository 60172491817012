import { Box } from '@mui/material'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import Rating from '@rentspree/component-2023.components.organisms.rating'
import React from 'react'

export type OverallRatingProps = {
  average: number
  total: number
}

export const OverallRating: React.FC<OverallRatingProps> = ({
  average = 0,
  total = 0,
}: OverallRatingProps) => (
  <Box>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="8px"
    >
      <Typography variant="title-large">{average}</Typography>
      <Rating name="size-medium" value={average} disabled style={{ opacity: 1 }} />
      <Typography variant="body">
        ({total} {total > 1 ? 'Reviews' : 'Review'})
      </Typography>
    </Box>
  </Box>
)
