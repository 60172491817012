import type { IFeatureFlagData } from 'store/feature-flag/types'
import type { AppStore } from 'store/store'
import type { FeatureFlagValue, IGetFeatureFlag } from 'utils/feature-flag/instance'

import { useSelector } from 'store'
import { actions as featureFlagActions, selectFeatureFlagData } from 'store/feature-flag'

export const useDispatchFeatureFlagValueByKeys = async (
  store: AppStore,
  featureFlagKeys: IGetFeatureFlag[],
): Promise<void> => {
  if (featureFlagKeys.length === 0) {
    return
  }

  // TODO: call to get Statsig feature flag value for each feature flag
  const promises = featureFlagKeys.map(() => [false])
  const results = await Promise.all(promises)
  results.forEach((result, i) => {
    store.dispatch(
      featureFlagActions.setFeatureFlag({
        key: featureFlagKeys[i],
        value: result as FeatureFlagValue,
      }),
    )
  })
}

// hook that returns the feature flag value for the given key
export const useFeatureFlagFromStore = (
  featureFlagKey: keyof IFeatureFlagData,
): FeatureFlagValue => {
  const featureFlagAll = useSelector(selectFeatureFlagData)
  return featureFlagAll?.[featureFlagKey] || [false]
}
