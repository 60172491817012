import { OverallCompliment } from '@rentspree/component-v3.components.agent-profile.components.overall-compliment'
import { OverallRating } from '@rentspree/component-v3.components.organisms.overall-rating'

import { useSelector } from 'store'
import { selectAgentReviewSummary } from 'store/agent-review-summary/selectors'

import { Container, DividerColumn, DividerRow, Wrapper } from './style'

export const SummaryReview: React.FC = () => {
  const summary = useSelector(selectAgentReviewSummary)

  const { compliments, average, totalReviews: total } = summary

  return (
    <Container>
      <Wrapper $width="340px">
        <OverallRating isUIKitV4 average={average} total={total} />
      </Wrapper>
      <DividerColumn $hideMobile />
      <DividerRow $hideDesktop $mMargin="20px 0 15px 0" />
      <Wrapper $width="470px" $ml="30" $mMargin="0">
        <OverallCompliment isUIKitV4 compliments={compliments} rowGap="13px" width="100%" />
      </Wrapper>
    </Container>
  )
}
