import type { IReviewItem } from 'store/agent-review-list/types'

import { ReviewItem } from '@rentspree/component-v3.components.organisms.review-item'
import { useRouter } from 'next/router'

import { Hr } from 'components'
import { Pagination } from 'components/common/organisms/pagination'
import { useSelector } from 'store'
import {
  selectAgentReviewList,
  selectAgentReviewListPagination,
} from 'store/agent-review-list/selectors'

import { ReviewSection, WrappedReview, WrappedPagination } from './style'

interface IReviewList {
  status?: 'submitted' | 'published'
}

export const ReviewList: React.FC<IReviewList> = () => {
  const router = useRouter()
  const list = useSelector(selectAgentReviewList)
  const pagination = useSelector(selectAgentReviewListPagination)

  const onPageChange = async (current: number) => {
    await router.replace({ query: { ...router.query, agentReviewPage: current } }, undefined, {
      scroll: false,
    })
  }

  if (!pagination.total) {
    return null
  }

  return (
    <>
      <WrappedReview>
        {list.map((review: IReviewItem) => (
          <ReviewSection key={review.id} data-testid="review-item">
            <ReviewItem
              firstName={review.reviewer?.firstName}
              lastName={review.reviewer?.lastName}
              reviewDate={review.reviewDate}
              renterRole={review.reviewer?.role}
              star={review.star}
              review={review.recommendation}
              compliments={review.compliments}
              isUIKitV4
            />
            <Hr $margin="16px 50px 0 50px" $mMargin="16px 0 0 40px" />
          </ReviewSection>
        ))}
      </WrappedReview>
      <WrappedPagination>
        <Pagination
          isAllPage={false}
          total={pagination.maxPage ?? 1}
          page={pagination.page ?? 1}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPageChange={onPageChange}
        />
      </WrappedPagination>
    </>
  )
}
