import styled from 'styled-components'

import { Div } from 'components/common/atoms'
import { elementStyle } from 'styles/element-style'

export const Container = styled(Div)`
  display: flex;
  background: #ffffff;

  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  height: 232px;
  padding: 28px 47px 22px 40px;
  margin-top: 24px;

  @media only screen and (max-width: 991px) {
    display: block;
    height: unset;
    padding: 15px 20px;
  }
`

export const Wrapper = styled(Div)`
  ${elementStyle}
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
`

export const DividerColumn = styled(Div)`
  border-left: 1px solid #e5e5e5;
  max-height: 100%;
  margin: 35px 20px 7px;
  ${elementStyle}
`

export const DividerRow = styled(Div)`
  border-top: 1px solid #e5e5e5;
  display: ${({ $hideDesktop }) => ($hideDesktop ? 'none' : 'block')};
  margin-top: 8px;
  ${elementStyle}
  @media only screen and (max-width: 991px) {
    display: ${({ $hideDesktop }) => ($hideDesktop ? 'block' : 'none')};
  }
`
