import styled from 'styled-components'

import { Div } from 'components'

export const ReviewSection = styled(Div)`
  margin-top: 20px;
`

export const WrappedReview = styled(Div)`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  @media (max-width: 991px) {
    justify-content: center;
  }
`

export const WrappedPagination = styled(Div)`
  text-align: center;
`

export const WrappedEmptyList = styled(Div)`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 100%;
`

export const WrappedContent = styled(Div)`
  ${({ $mb }) => $mb && `margin-bottom: ${$mb}px;`}
  @media (max-width: 991px) {
    ${({ $mMargin }) => $mMargin && `margin: ${$mMargin};`}
  }
`
