import styled from 'styled-components'

import { Hr, Img } from 'components'
import { COLOR } from 'styles/constants'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1280px;
  align-items: center;

  @media (max-width: 991px) {
    width: 100%;
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 920px;
  min-width: 590px;

  @media (max-width: 991px) {
    flex-direction: column;
    width: 100%;
    min-width: unset;
    padding: 20px;
    justify-content: center;
    align-items: center;
  }
`

export const Panel = styled.div<{ width?: string; margin?: string }>`
  ${props => props.width && `width: ${props.width};`}
  ${props => props.margin && `margin: ${props.margin};`}
  display: flex;
  flex-direction: column;
  max-width: 710px;

  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
  }
`

export const StyleHr = styled(Hr)`
  margin: 20px 0;
`

export const CoverImage = styled(Img)`
  height: 320px;
  width: 1280px;
  position: absolute;
  z-index: 0;

  @media (max-width: 991px) {
    width: 100%;
    height: calc(100vw / 4);
  }
`

export const ProfileImageWrapper = styled.div`
  display: flex;
  width: 920px;
  margin-top: 235px;
  z-index: 1;

  @media (max-width: 991px) {
    width: 100%;
    margin-top: calc(100vw / 6);
    justify-content: center;
  }
`

export const ProfileImage = styled(Img)`
  height: 136px;
  width: 136px;
  border-radius: 100%;
`

export const FontWrapper = styled.div<{ margin?: string }>`
  font-family: 'Poppins';

  color: ${COLOR.GREY_LV1};
  p,
  span,
  label,
  button,
  input {
    font-family: 'Poppins';
  }

  ${props => props.margin && `margin: ${props.margin};`}
`
