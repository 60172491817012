import type { RefObject } from 'react'

import { useEffect } from 'react'

export const EVENT_NAME = {
  USE_EFFECT: 'USE_EFFECT',
  ON_READY: 'ON_READY',
  ON_TOGGLE: 'ON_TOGGLE',
  ON_MESSAGE: 'ON_MESSAGE',
  CALCULATE_HEIGHT: 'CALCULATE_HEIGHT',
}

export type CurrentRefType = RefObject<HTMLDivElement | HTMLElement>

export const usePreviewPostMessage = (
  refCurrent: CurrentRefType,
  refCurrentHandler: (CurrentRefType) => number,
): void => {
  const postMessage = (message: IframeMessageInterface<unknown>) => {
    window.parent.postMessage(message, '*')
  }

  useEffect(() => {
    postMessage({ eventName: EVENT_NAME.USE_EFFECT, payload: null })
    const handler = ({ data }) => {
      const iframeData = data as IframeMessageInterface<null>
      if (iframeData.eventName === EVENT_NAME.CALCULATE_HEIGHT) {
        const calculatedHeight = refCurrentHandler(refCurrent)
        postMessage({
          eventName: EVENT_NAME.ON_MESSAGE,
          payload: { height: calculatedHeight },
        })
      }
    }
    window.addEventListener('message', handler)
    return () => {
      window.removeEventListener('message', handler)
    }
  })
}
