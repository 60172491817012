import type { StatsigUser } from 'statsig-node'

import Statsig from 'statsig-node'

import env, { serverEnv } from 'env'

import { UNAUTHENTICATED_USER_ID } from './constants'

export type IGetFeatureFlag = string

export type FeatureFlagValue = [boolean] | [boolean, unknown, unknown, unknown]

export type StatsigInitializeValues = {
  clientKey: string
  user: StatsigUser
  values: string
}

export async function getStatsigInitializeValues(): Promise<StatsigInitializeValues> {
  await Statsig.initialize(serverEnv.STATSIG_SERVER_SECRET, {
    environment: { tier: env.STATSIG_ENVIRONMENT },
  })

  const unauthenticatedUser = { userID: UNAUTHENTICATED_USER_ID }

  const values = Statsig.getClientInitializeResponse(unauthenticatedUser, undefined, {
    hash: 'djb2',
  })

  return {
    clientKey: env.STATSIG_CLIENT_SECRET,
    user: unauthenticatedUser,
    values: JSON.stringify(values),
  }
}
