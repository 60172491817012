import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

export interface IUseHashRouter<T> {
  scrollTargetElementRef: React.RefObject<T>
}

interface IUseHashRouterProp {
  id: string
  offset?: number
}

export function useHashRouter<T extends HTMLElement>({
  id,
  offset = -100,
}: IUseHashRouterProp): IUseHashRouter<T> {
  const router = useRouter()
  const scrollTargetElementRef = useRef<T | null>(null)

  useEffect(() => {
    const isMatch = router.asPath.split('#')?.[1] === id
    if (isMatch && window) {
      window.scrollTo({
        top: (scrollTargetElementRef?.current?.offsetTop ?? 0) + offset,
        behavior: 'smooth',
      })
    }
  }, [router, id, offset])

  return { scrollTargetElementRef }
}
