import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Box } from '@mui/material'
import IconButton from '@rentspree/component-2023.components.atoms.icon-button'
import Tooltip from '@rentspree/component-2023.components.atoms.tooltip'
import Typography from '@rentspree/component-2023.components.atoms.typography'

import { useSelector } from 'store'
import { selectAgentProfileFullName, selectAgentVerifiedStatus } from 'store/agent-profile'

interface IProfessionalProfileHeader {
  coverImageSrc: string
  profileImageSrc: string

  setProfileImageSrc: (src: string) => void
}

export const ProfessionalProfileHeader: React.FC<IProfessionalProfileHeader> = ({
  coverImageSrc,
  profileImageSrc,
  setProfileImageSrc,
}) => {
  const defaultProfileImage = '/profile/default-profile.svg'

  const fullName = useSelector(selectAgentProfileFullName)
  const isAgentVerified = useSelector(selectAgentVerifiedStatus)

  return (
    <Box marginBottom="24px">
      <Box>
        <img src={coverImageSrc} alt="agent profile cover" className="cover-image" />
      </Box>
      <Box className="profile-infomation-container">
        <img
          className="profile-image"
          src={profileImageSrc}
          alt="agent profile"
          onError={() => setProfileImageSrc(defaultProfileImage)}
        />
        <Box className="fullname-information">
          <Typography component="span" variant="title-medium">
            {fullName}
          </Typography>
          {isAgentVerified && (
            <Tooltip title="Account verified" placement="top">
              <IconButton>
                <CheckCircleIcon color="success" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  )
}
