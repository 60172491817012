import { useMemo } from 'react'

import { H3, Body } from 'components'
import { useSelector } from 'store'
import { useHashRouter } from 'store/@hooks/use-hash-router'
import { selectAgentReviewListPagination } from 'store/agent-review-list'
import { selectAgentReviewSummaryTotalReviews } from 'store/agent-review-summary'

import { ReviewList } from './review-list-old'
import { SummaryReviewOld } from './review-summary'
import { WrappedContent } from './style'

interface IReview {
  offsetScroll?: number
  isDisplayFooter?: boolean
}

export const Review: React.FC<IReview> = ({ isDisplayFooter = false, offsetScroll = 100 }) => {
  const agentReviewTotalReviews = useSelector(selectAgentReviewSummaryTotalReviews)
  const pagination = useSelector(selectAgentReviewListPagination)

  const { scrollTargetElementRef } = useHashRouter<HTMLDivElement>({
    id: 'review',
    offset: offsetScroll - 100,
  })

  const calSpacingDependOnFooter = (isDisplay: boolean, total: number): number => {
    const defaultSpacing = isDisplay ? 0 : 30
    const isShowPagination = total > 1
    return isShowPagination ? defaultSpacing + 50 : defaultSpacing + 70
  }

  const calSpaceMemo = useMemo(() => {
    const totalPage = pagination.maxPage ?? 1
    return calSpacingDependOnFooter(isDisplayFooter, totalPage)
  }, [isDisplayFooter, pagination.maxPage])

  const calSpaceMobileMemo = useMemo(() => {
    const totalPage = pagination.maxPage ?? 1
    const bottomSpacePagination = totalPage > 1 ? 0 : 20
    const marginBottom =
      calSpacingDependOnFooter(isDisplayFooter, totalPage) - bottomSpacePagination
    return `0 0 ${marginBottom}px`
  }, [isDisplayFooter, pagination?.maxPage])

  return (
    <section id="review" ref={scrollTargetElementRef}>
      <H3 $mTextStyle="h2" $mt="32" $mMargin="24px 0 0">
        Reviews ({agentReviewTotalReviews})
      </H3>
      {agentReviewTotalReviews !== 0 ? (
        <WrappedContent $mb="50" $mMargin="0 0 58px 0">
          <SummaryReviewOld />
          <ReviewList />
        </WrappedContent>
      ) : (
        <Body $block $mt="10" $mb={calSpaceMemo} $mMargin={calSpaceMobileMemo}>
          There is no review yet.
        </Body>
      )}
    </section>
  )
}
