import type { CookieValueTypes, OptionsType } from 'cookies-next/lib/types'

import { checkCookies, getCookie as getCookieNext, setCookies } from 'cookies-next'

export function findOrCreateCookie(
  key: string,
  value: string,
  options?: OptionsType,
): CookieValueTypes {
  if (checkCookies(key, options)) {
    return getCookieNext(key, options)
  }
  setCookies(key, value, options)
  return value
}

export interface IGetCookieOption {
  key: string
  parseFunction?: (cookie: string) => string
}

export function getCookie(context: OptionsType | undefined, option: IGetCookieOption): string {
  const cookie = getCookieNext(option.key, context) as string
  if (option.parseFunction) {
    return option.parseFunction(cookie)
  }
  return cookie
}
